import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import GroupPropTypes from 'propTypes/group/GroupPropTypes';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';
import ActionColumnPropTypes from 'propTypes/table/ActionColumnPropTypes';

const GroupTable = ({ groups, sortBy, setSortBy, sortDirection, actionColumn }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first, { id }) => (<Link to={`/groups/${id}`}>{first}</Link>),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('description'),
        className: 'clickable',
      }),
    },
  ];

  if (actionColumn) columns.push(actionColumn);

  return (
    <InteractiveTable
      dataId="id"
      data={groups}
      columns={columns}
      sortDirection={sortDirection}
    />
  );
};

GroupTable.defaultProps = {
  actionColumn: null,
};

GroupTable.propTypes = {
  ...SortPropTypes,
  groups: PropTypes.arrayOf(GroupPropTypes).isRequired,
  actionColumn: ActionColumnPropTypes,
};

export default GroupTable;
