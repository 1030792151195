import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import GroupPropTypes from 'propTypes/group/GroupPropTypes';
import GroupTable from 'common/group/GroupTable';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';

const AddGroupTable = (props) => {
  const { groups, addSelectedGroup, currentGroups, loading, stagedGroupHash, setSortBy, sortBy, sortDirection, setSortDirection } = props;

  const currentGroupHash = useMemo(() => {
    const groupHash = {};
    currentGroups.forEach(group => {
      groupHash[group.id] = group;
    });
    return groupHash;
  }, [currentGroups]);

  return (
    <GroupTable
      groups={groups}
      setSortBy={setSortBy}
      sortBy={sortBy}
      setSortDirection={setSortDirection}
      sortDirection={sortDirection}
      actionColumn={{
        title: '',
        dataIndex: 'id',
        onTd: () => ({ className: 'text-center' }),
        render: (_, group) => {
          if (currentGroupHash[group.id]) return (<span className="text-success">{translate('added')}</span>);
          if (stagedGroupHash[group.id]) return (<span className="text-warning">{translate('queued')}</span>);
          return (
            <Button className="btn-xs" onClick={() => addSelectedGroup(group)} disabled={loading}>
              <FontAwesomeIcon icon="plus" />
            </Button>
          );
        },
      }}
    />
  );
};

AddGroupTable.propTypes = {
  ...SortPropTypes,
  groups: PropTypes.arrayOf(GroupPropTypes).isRequired,
  addSelectedGroup: PropTypes.func.isRequired,
  currentGroups: PropTypes.arrayOf(GroupPropTypes).isRequired,
  loading: PropTypes.bool.isRequired,
  stagedGroupHash: PropTypes.object.isRequired,
  setSortBy: PropTypes.func.isRequired,
};

export default AddGroupTable;
